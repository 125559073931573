<template>
  <div class="home z-0">
    <div class="mx-2 mb-10 page-title-text relative">
      <h1 class="font-medium cursor-default text-2xl">
        Eventos em
        <div class="inline-flex items-center cursor-pointer" @click.stop.prevent="isCitySelectorModalOpen = !isCitySelectorModalOpen">
          <span class="font-normal tracking-normal">{{ currentCity ? currentCity : 'Curitiba' }}</span>
          <icon-chevron-down />
        </div>
      </h1>

      <transition
        enter-active-class="transition ease-out duration-300"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition ease-in duration-200"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0">
        <div v-if="isCitySelectorModalOpen" id="dropdownHover" class="z-10 left-36 bg-white absolute divide-y divide-gray-100 rounded-lg shadow-lg w-44 dark:bg-stone-900">
          <ul class="py-2 text-gray-700 dark:text-gray-200" aria-labelledby="dropdownHoverButton">
            <li v-for="city in cities" :key="`idx-${city.name}`"
            class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
            @click="selectCity(city.name)">
              {{ city.name }} <small>({{ city.count }})</small>
            </li>
          </ul>
        </div>
      </transition>
    </div>


    <div class="grid sm:gap-2 sm:grid-cols-2 md:gap-4 md:grid-cols-2 lg:gap-8 lg:grid-cols-3">
      <NuxtLink class="w-full" :to="`/u/${event.slug}`" v-for="event,idx in healthEvents" :key="idx">
        <EventCardFull :event="event" :isLoading="isLoading" />
      </NuxtLink>
    </div>

    <PixtaApiError v-if="isApiOnError" />
  </div>
</template>

<script setup>

import { storeToRefs } from 'pinia'
import { useUserStore } from '@/store/user.js'

const userStore = useUserStore()
const { currentCity, loggedIn: isLoggedIn } = storeToRefs(userStore)

const events = ref([]);
const isSeeOldEvents = ref(true);
const isCitySelectorModalOpen = ref(false);
const isApiOnError = ref(false);

const route = useRoute()

const runtimeConfig = useRuntimeConfig()
const { baseApiUrl: baseUrl } = runtimeConfig.public

useHead(() => {
  const pageTitle = currentCity ? `Eventos em ${currentCity.value || 'Curitiba'}` : `Ingressos para seu evento favorito`,
        pageDescription = `Festa cheia, sem stress. Ingressos para seu evento favorito na pixta.me`,
        pageImage = `https://pixta.me/og.jpeg`
  return {
    title: pageTitle,
    description: pageDescription,
    meta: [
      { property: 'og:image', content: pageImage },
      { property: 'og:image:secure_url', content: pageImage },
      { property: 'og:image:type', content: 'image/jpeg' },
      { property: 'og:image:width', content: '1200' },
      { property: 'og:image:height', content: '525' },
      { property: 'og:image:alt', content: pageTitle },
      { property: 'og:title', content: pageTitle },
      { property: 'og:site_name', content: 'PIXTA.me' },
      { property: 'og:url', content: `https://pixta.me` },
      { property: 'og:description', content: pageDescription },
      { property: 'og:type', content: 'website' },
      { property: 'fb:app_id', content: '1751665528367178' },
      { property: 'og:locale', content: 'pt_BR' },
      { itemprop: 'name', content: 'PIXTA.me' },
      { itemprop: 'description', content: pageDescription },
      { itemprop: 'image', content: pageImage },
      { name: 'description', content: pageDescription },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:site', content: 'pixta_me' },
      { name: 'twitter:title', content: pageTitle },
      { name: 'twitter:description', content: pageDescription },
      { name: 'twitter:image', content: pageImage },
    ]
  }
})

const pageImage = computed(() => `https://pixta.me/og.jpeg`);

const cities = computed(() => {
  try {
    const filteredCities = currentHealth.value.cities.filter((c) => c.name !== '');
    return filteredCities;
  } catch (_) {
    return ['Curitiba'];
  }
});

const healthEvents = computed(() => {
  try {
    return currentHealth.value.events;
  } catch (_) {
    return [];
  }
});

const currentEvents = computed(() => {
  try {
    if (isSeeOldEvents.value) {
      return events.value;
    } else {
      return events.value.filter((e) => Date.parse(e.event_starts_at) > new Date());
    }
  } catch (_) {
    return [];
  }
});

const shuffledEvents = computed(() => {
  try {
    return currentEvents.value
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);
  } catch (_) {
    return currentEvents.value;
  }
});

const { pending: isLoading, refresh, data: currentHealth } = await useFetch(() => `${baseUrl}/api/health.json?city=${currentCity.value}`)
// , { server: true }
// console.log(pending, dataRes.value)
// isLoading.value = pending.value

// async function serverPrefetch() {
//   // component is rendered as part of the initial request
//   // pre-fetch data on server as it is faster than on the client
//   // this.data = await fetchOnServer(/* ... */)
//   await health();
// }

watch(route, () => {
  isCitySelectorModalOpen.value = false;
});

const selectCity = async (cityName) => {
  await userStore.changeCity(cityName);
  isCitySelectorModalOpen.value = false;
  // refresh()
}

</script>
